"use strict";
/*----------------------------------------------------------------------------
 Window Error Handler
----------------------------------------------------------------------------*/
/* JS Dependencies
--------------------------------------*/
import SentryController from "../controller/SentryController";

export default class ErrorHandler {
    static init() {
        const sentryController = new SentryController();

        window.onerror = (e) => {
            sentryController.sendError(e);
        };
    }
}
