"use strict";
/*----------------------------------------------------------------------------
 Sentry Controller Class
----------------------------------------------------------------------------*/
/* JS Dependencies
--------------------------------------*/
import * as Sentry from '@sentry/browser';
import settings from "../settings";

export default class SentryController {
    constructor() {
        this.envsAllowSentryLogs = settings.getOption('ENVS_ALLOW_SENTRY_LOGS');
        const sentryDsn = window.env.SENTRY_DSN;

        if (sentryDsn != null || sentryDsn != '') {
            Sentry.init({
                dsn: sentryDsn,
                ignoreErrors: [/unhandledRejection\d\d/, 'unhandledRejection'],
            });
        }
    }

    sendError(error) {
        if (this.envsAllowSentryLogs.includes(window.env.APP_ENV)) {
            Sentry.captureException(error);
        }
    }
}
