"use strict";
/*----------------------------------------------------------------------------
 Project Settings
----------------------------------------------------------------------------*/
/* DateTime settings section
--------------------------------------*/
const TIMEZONE = 'Europe/Berlin';

/* Services section
--------------------------------------*/
const ENVS_ALLOW_SENTRY_LOGS = [
    'prod',
    'staging',
];

/* Settings getter
------------------------------------*/
export default {
    getOption(name) {
        try {
            return eval(name);
        } catch (e) {
            throw new Error(`Settings key ${name} does not exist`);
        }
    }
};
