"use strict";
/*----------------------------------------------------------------------------
 Global Admin
----------------------------------------------------------------------------*/
/* JS Dependencies
--------------------------------------*/
import ErrorHandler from "./module/ErrorHandler";

/* CSS Dependencies
--------------------------------------*/
import '../css/app.scss';

/** Default Error Handling */
ErrorHandler.init();
